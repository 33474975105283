<template>
    <div>
        <Header></Header>
        <div class="mt-16 container mx-auto px-4 py-6" style="max-width: 1140px">
            <!-- Navigation Tabs -->
            <div class="flex items-center gap-10 border-b border-gray-100">
                <a href="#" class="text-gray-400 pb-4 text-sm ">全部 <span class="">{{ listCount }}</span></a>
                <a href="#" class="text-blue-500 pb-4 border-b-[3px] border-blue-500 text-sm px-4">曲谱 <span class="">{{
                    musicSheets.length }}</span></a>
                <a href="#" class="text-gray-400 pb-4 text-sm px-4">演奏课程 <span class="">0</span></a>
                <a href="#" class="text-gray-400 pb-4 text-sm px-4">音源 <span class="">0</span></a>
                <a href="#" class="text-gray-400 pb-4 text-sm px-4">歌曲 <span class="">3</span></a>
                <a href="#" class="text-gray-400 pb-4 text-sm px-4">目录 <span class="">3</span></a>
            </div>

            <!-- Filter Buttons -->
            <div class="flex items-center gap-4 my-6">
                <span class="text-lg text-gray-500">曲谱 <span class="bg-gray-100 text-xs px-2 py-0.5 rounded">{{
                    listCount }}</span></span>
                <div class="flex-1"></div>
                <div class="relative">
                    <button
                        class="px-4 py-2 text-gray-500 text-sm border rounded-lg hover:bg-gray-50 flex items-center gap-2"
                        @click="toggleDropdown">
                        {{ selectedOption }}
                        <BiChevronDown size="16" color="#999" />
                    </button>
                    <div v-if="isDropdownOpen"
                        class="absolute right-0 z-10 bg-white border rounded-lg shadow-lg mt-1 w-[250px]">
                        <div v-for="option in options" :key="option" class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            @click="selectOption(option)">
                            {{ option }}
                        </div>
                    </div>
                </div>
                <div class="relative">
                    <button
                        class="px-4 py-2 text-gray-500 text-sm border rounded-lg hover:bg-gray-50 flex items-center gap-2"
                        @click="toggleInstrumentDropdown">
                        {{ selectedInstrument }}
                        <BiChevronDown size="16" color="#999" />
                    </button>
                    <div v-if="isInstrumentDropdownOpen"
                        class="absolute z-10 bg-white border rounded-lg shadow-lg mt-1">
                        <div v-for="instrument in instruments" :key="instrument"
                            class="px-4 py-2 hover:bg-gray-100 cursor-pointer" @click="selectInstrument(instrument)">
                            {{ instrument }}
                        </div>
                    </div>
                </div>
                <button
                    class="px-4 py-2 text-gray-500 text-sm border rounded-lg hover:bg-gray-50 flex items-center gap-2"
                    @click="showModal">
                    <MdTune size="16" color="#999" />过滤选项
                </button>
                <div class="flex gap-2 w-[200px] bg-gray-100 justify-between px-1 py-1 rounded-lg">
                    <button class="px-4 py-2 w-1/2 rounded-lg text-sm" 
                        :class="{ 'bg-white text-gray-500': selectedTab === '曲谱', 'text-gray-400': selectedTab !== '曲谱' }" 
                        @click="selectedTab = '曲谱'">曲谱</button>
                    <button class="px-4 py-2 w-1/2 rounded-lg text-sm" 
                        :class="{ 'bg-white text-gray-500': selectedTab === '表演视频', 'text-gray-400': selectedTab !== '表演视频' }" 
                        @click="selectedTab = '表演视频'">表演视频</button>
                </div>
            </div>

            <!-- Search Results -->
            <div class="space-y-4">
                <div v-for="(sheet, index) in musicSheets" :key="index"
                    class="flex items-center py-4 px-2 border-b border-gray-100 justify-between text-xs gap-6 hover:bg-gray-50 cursor-pointer">
                    <div class="w-[320px]">
                        <h3 class="font-medium" @click.prevent="goSheetDetail(sheet.id)">{{ sheet.title }}</h3>
                        <p class="text-sm text-gray-600">{{ sheet.composer }}</p>
                    </div>
                    <div class="flex items-center gap-2 w-[150px]">
                        <img :src="sheet.avatar" alt="" class="w-[30px] h-[30px] rounded-full">
                        <div class="text-gray-600">{{ sheet.author }}</div>
                    </div>
                    <div class="text-gray-600 ">{{ sheet.instruments.map(x => x.name).join(', ') }}</div>
                    <div class="text-gray-600 text-center w-[200px]">
                        {{
                            sheet.difficulty === 1 ? '初学者' :
                                sheet.difficulty === 2 ? '容易' :
                                    sheet.difficulty === 3 ? '普通' :
                                        sheet.difficulty === 4 ? '高难度' :
                                            sheet.difficulty === 5 ? '专业级' :
                                                ''
                        }}
                    </div>
                    <div class="flex gap-2">
                        <div class="font-medium w-[80px]">￥{{ parseFloat(sheet.price)?.toFixed(2) }}</div>
                        <button class="px-1 py-1 border rounded hover:bg-gray-50" @click.stop="addToCart(sheet.id)">
                            <AiOutlineShopping size="14" color="#2188FF" />
                        </button>
                        <button class="px-1 py-1">
                            <BiFile size="14" color="#999" />
                        </button>
                    </div>

                </div>
            </div>

            <!-- Pagination -->
            <div class="mt-6 flex justify-center gap-2 text-xs">
                <button class="px-1 py-1 border rounded hover:bg-gray-50" :disabled="currentPage === 1" @click="prev()">
                    <CgChevronLeft size="16" color="#777" />
                </button>
                <button v-for="(page, index) in pages" :key="index" class="px-2 py-1 rounded"
                    :class="{ 'bg-blue-100 text-blue-500': page === currentPage }" @click="setCurrentPage(page)">{{ page
                    }}</button>
                <button class="px-1 py-1 border rounded hover:bg-gray-50" :disabled="currentPage === pages.length"
                    @click="next()">
                    <CgChevronRight size="16" color="#777" />
                </button>
            </div>

            <!-- Collections Section -->
            <div class="mt-12">
                <div class="flex items-center justify-between">
                    <h2 class="text-sm text-gray-500 mb-4">曲谱集 <span class="bg-gray-100 text-xs px-2 py-0.5 rounded">{{
                            collections.length }}</span></h2>
                    <div class="flex gap-2">
                        <div class="left cursor-pointer border px-2 py-2 rounded" @click="prev()"
                            :disabled="currentPage === 1">
                            <CgChevronLeft size="16" color="#777" />
                        </div>
                        <div class="right cursor-pointer border px-2 py-2 rounded" @click="next()"
                            :disabled="currentPage === pages.length">
                            <CgChevronRight size="16" color="#777" />
                        </div>
                    </div>
                </div>

                <div class="space-y-4">
                    <div v-for="(collection, index) in collections" :key="index"
                        class="flex items-center justify-between py-2 border-b border-gray-100 hover:bg-gray-50">
                        <div class="flex items-center gap-4">
                            <img :src="collection.image" alt="" class="w-12 h-12 rounded" />
                            <div>
                                <h3 class="text-sm font-medium">{{ collection.title }}</h3>
                                <p class="text-xs text-gray-500">{{ collection.description }}</p>
                            </div>
                        </div>
                        <div class="flex items-center gap-4">
                            <div class="flex flex-col items-end">
                                <div class="flex gap-1">
                                    <span class="text-xs text-blue-500">{{ collection.discount }}%</span>
                                    <span class="text-xs text-gray-400 line-through">{{ collection.originalPrice
                                        }}</span>
                                </div>
                                <span class="text-gray-500 text-xs">{{ collection.currentPrice }}</span>
                            </div>

                            <BiFile size="14" color="#999" class="cursor-pointer" />
                        </div>
                    </div>
                </div>
            </div>
            <Modal v-if="isModalOpen" @close="isModalOpen = false"></Modal>
        </div>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
import Header from "../../components/layout/Header.vue";
import Footer from "../../components/layout/Footer.vue";
import { AiOutlineShopping, AiOutlineMail, AiFillSafetyCertificate } from "vue-icons-plus/ai"
import { CgChevronLeft, CgChevronRight } from "vue-icons-plus/cg";
import { BiFile, BiChevronDown } from "vue-icons-plus/bi"
import { MdTune } from "vue-icons-plus/md"
import { getSheetDetail, getSheetCount, getSheetList } from "@/api/sheet"
import { useRouter } from "vue-router"
import { onMounted } from "vue";
import Modal from "./modal.vue";

const router = useRouter()
const musicSheets = ref([])
const currentPage = ref(1)
const pageSize = ref(10)
const listCount = ref(0)
const pages = computed(() => {
    return Array.from({ length: Math.ceil(listCount.value / pageSize.value) }, (_, i) => i + 1)
})


const collections = ref([
    {
        title: "圣诞节赞美诗集",
        description: "包含多首经典圣诞歌曲",
        image: "/images/christmas-collection.jpg",
        discount: 30,
        originalPrice: "¥130.23",
        currentPrice: "¥91.16"
    },
    {
        title: "春季乐曲集",
        description: "春天的旋律，带来温暖",
        image: "/images/spring-collection.jpg",
        discount: 20,
        originalPrice: "¥100.00",
        currentPrice: "¥80.00"
    },
    {
        title: "经典钢琴曲集",
        description: "收录多首经典钢琴曲",
        image: "/images/piano-collection.jpg",
        discount: 15,
        originalPrice: "¥150.00",
        currentPrice: "¥127.50"
    },
    {
        title: "流行歌曲集",
        description: "最新流行歌曲的合集",
        image: "/images/pop-collection.jpg",
        discount: 10,
        originalPrice: "¥200.00",
        currentPrice: "¥180.00"
    }
]);

const selectedTab = ref('曲谱'); // 默认选中“曲谱”
const options = ref(['准备度顺序', '选项 1', '选项 2', '选项 3']); // 下拉选项
const selectedOption = ref(options.value[0]); // 默认选中第一个选项
const isDropdownOpen = ref(false); // 控制下拉框的显示

const instruments = ref(['钢琴', '吉他', '小号', '小提琴']); // 乐器选项
const selectedInstrument = ref(instruments.value[0]); // 默认选中第一个乐器
const isInstrumentDropdownOpen = ref(false); // 控制乐器下拉框的显示
const isModalOpen = ref(false); // 控制Modal的显示
const isInstrumentDropdown = ref(false);

const toggleDropdown = () => {
    isDropdownOpen.value = !isDropdownOpen.value; // 切换下拉框的显示状态
};

const selectOption = (option) => {
    selectedOption.value = option; // 更新选中的选项
    isDropdownOpen.value = false; // 关闭下拉框
};


const toggleInstrumentDropdown = () => {
    isInstrumentDropdownOpen.value = !isInstrumentDropdown.value; // 切换乐器下拉框的显示状态
};

const selectInstrument = (instrument) => {
    selectedInstrument.value = instrument; // 更新选中的乐器
    isInstrumentDropdownOpen.value = false; // 关闭下拉框
};

const showModal = () => {
    isModalOpen.value = true; // 显示Modal
};

const fetchSheetList = async (instrument_id) => {
    const params = instrument_id ? { instrument: instrument_id, limit: pageSize.value, offset: (currentPage.value - 1) * pageSize.value } : { limit: pageSize.value, offset: (currentPage.value - 1) * pageSize.value };
    listCount.value = (await getSheetCount(params)).data;
    const sheets = (await getSheetList(params)).data;
    sheets.forEach(element => {
        element.avatar = `${window.API.DEV_SERVER}/file/user/${element.artistID}/avatar`
    });
    musicSheets.value = sheets;
    if (instrument_id) {
        router.push({ query: { instrument: instrument_id } });
    } else {
        router.push({ query: {} });
    }
}

const setCurrentPage = (page) => {
    currentPage.value = page
    fetchSheetList(router.currentRoute.value.query.instrument);
}

const prev = () => {
    if (currentPage.value > 1) {
        setCurrentPage(currentPage.value - 1)
    }
}

const next = () => {
    if (currentPage.value < pages.value.length) {
        setCurrentPage(currentPage.value + 1)
    }
}

const goSheetDetail = (id) => {
    router.push({ path: 'detail/' + id })
}

onMounted(() => {
    fetchSheetList(router.currentRoute.value.query.instrument);
});
</script>