<template>
  <div class="relative">
    <!-- Search Input -->
    <div
      class="flex items-center bg-white rounded-lg border border-gray-200 hover:border-gray-300"
    >
      <div class="flex items-center px-3 py-2">
        <MdSearch class="h-5 w-5 text-gray-400" />
        <input
          type="text"
          placeholder="按歌曲名、音乐人、歌手或伴奏"
          class="ml-2 outline-none text-sm text-gray-600 placeholder-gray-400 w-64"
          @focus="showDropdown = true"
          @blur="handleBlur"
        />
      </div>
    </div>

    <!-- Dropdown Panel -->
    <div
      v-if="showDropdown"
      class="absolute top-full left-0 w-[500px] mt-1 bg-white rounded-lg shadow-lg border border-gray-100 py-4"
      @mouseenter="isHoveringDropdown = true"
      @mouseleave="isHoveringDropdown = false"
    >
      <!-- Search History -->
      <div class="px-4 mb-4">
        <div class="flex items-center justify-between mb-3">
          <div class="flex items-center text-gray-600">
            <MdOutlineHistory class="h-4 w-4 mr-2" />
            <span class="text-sm">搜索历史</span>
          </div>
          <button class="text-xs text-gray-400 hover:text-gray-600">
            清除
          </button>
        </div>
        <div class="flex flex-wrap gap-2">
          <button
            v-for="term in searchHistory"
            :key="term"
            class="flex items-center px-3 py-1 bg-gray-50 rounded-lg hover:bg-gray-100"
          >
            <span class="text-sm text-gray-600">{{ term }}</span>
            <MdSearch class="h-3 w-3 ml-1 text-gray-400" />
          </button>
        </div>
      </div>

      <!-- Hot Searches -->
      <div class="px-4 mb-4">
        <div class="flex items-center mb-3">
          <MdOutlineTrendingUp class="h-4 w-4 mr-2 text-gray-600" />
          <span class="text-sm text-gray-600">热门搜索</span>
        </div>
        <div class="flex flex-wrap gap-2">
          <button
            v-for="(term, index) in hotSearches"
            :key="term"
            class="flex items-center px-3 py-1 bg-gray-50 rounded-lg hover:bg-gray-100"
          >
            <span class="text-sm text-gray-400 mr-2">{{ index + 1 }}.</span>
            <span class="text-sm text-gray-600 group-hover:text-gray-800">{{
              term
            }}</span>
            <MdSearch
              class="h-3 w-3 ml-1 text-gray-400 opacity-0 group-hover:opacity-100"
            />
          </button>
        </div>
      </div>

      <!-- Recommended Artists -->
      <div class="px-4">
        <div class="flex items-center mb-3">
          <MdOutlinePerson class="h-4 w-4 mr-2 text-gray-600" />
          <span class="text-sm text-gray-600">MMS Recommend Artist</span>
        </div>
        <div class=" grid grid-cols-2 gap-2">
          <button
            v-for="artist in recommendedArtists"
            :key="artist.name"
            class="flex items-center w-full p-2 rounded-lg border border-gray-200 hover:bg-gray-50"
          >
            <img
              :src="artist.avatar"
              :alt="artist.name"
              class="w-8 h-8 rounded-full object-cover"
            />
            <span class="ml-3 text-sm text-gray-700">{{ artist.name }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import {
  MdSearch,
  MdOutlineHistory,
  MdOutlineTrendingUp,
  MdOutlinePerson,
} from "vue-icons-plus/md";

const showDropdown = ref(false);
const isHoveringDropdown = ref(false);

const handleBlur = () => {
  setTimeout(() => {
    if (!isHoveringDropdown.value) {
      showDropdown.value = false;
    }
  }, 200);
};

const searchHistory = ref(["life on mars", "call of silence", "123", "nihao1"]);

const hotSearches = ref([
  "乌之诗",
  "call of silence",
  "绝不会忘记",
  "未闻花名",
  "kyle xian",
  "咒术回战",
  "on my way",
]);

const recommendedArtists = ref([
  {
    name: "JHoony Piano",
    avatar: "https://imgproxy.mapia.io/v3/ZupMd9_3J9M85pjfvDsbht0onDUkpEfZ/g:ce/rs:fill:400:400:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NDFlMmY5NzU4MTMz/YWE0NDgzOWZmZTE1/NDU4M2Q3ZjcvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1r/b2tvbXU.jpg",
  },
  {
    name: "MIWHA",
    avatar: "https://imgproxy.mapia.io/v3/ZupMd9_3J9M85pjfvDsbht0onDUkpEfZ/g:ce/rs:fill:400:400:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NDFlMmY5NzU4MTMz/YWE0NDgzOWZmZTE1/NDU4M2Q3ZjcvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1r/b2tvbXU.jpg",
  },
  {
    name: "pianokkk",
    avatar: "https://imgproxy.mapia.io/v3/ZupMd9_3J9M85pjfvDsbht0onDUkpEfZ/g:ce/rs:fill:400:400:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NDFlMmY5NzU4MTMz/YWE0NDgzOWZmZTE1/NDU4M2Q3ZjcvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1r/b2tvbXU.jpg",
  },
  {
    name: "SuhanNam",
    avatar: "https://imgproxy.mapia.io/v3/ZupMd9_3J9M85pjfvDsbht0onDUkpEfZ/g:ce/rs:fill:400:400:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NDFlMmY5NzU4MTMz/YWE0NDgzOWZmZTE1/NDU4M2Q3ZjcvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1r/b2tvbXU.jpg",
  },
]);
</script>
