<template>
    <div class="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
        <div class="bg-white rounded-lg w-[480px] p-6">
            <!-- Header -->
            <div class="flex justify-between items-center mb-6">
                <h2 class="text-lg font-medium">过滤选项</h2>
                <button @click="closeModal" class="text-gray-400 hover:text-gray-600">
                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>

            <!-- Filter Form -->
            <form @submit.prevent="applyFilters" class="space-y-4">
                <div class="grid grid-cols-2 gap-4">
                    <!-- First Row -->
                    <div class="space-y-1.5">
                        <label class="text-sm text-gray-600">乐器类型</label>
                        <select v-model="filters.instrument"
                            class="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-gray-500 focus:border-gray-500">
                            <option value="all">全部</option>
                            <option value="piano">钢琴</option>
                            <option value="guitar">吉他</option>
                            <option value="violin">小提琴</option>
                        </select>
                    </div>
                    <div class="space-y-1.5">
                        <label class="text-sm text-gray-600">难度等级</label>
                        <select v-model="filters.difficulty"
                            class="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-gray-500 focus:border-gray-500">
                            <option value="all">全部</option>
                            <option value="beginner">初级</option>
                            <option value="intermediate">中级</option>
                            <option value="advanced">高级</option>
                        </select>
                    </div>

                    <!-- Second Row -->
                    <div class="space-y-1.5">
                        <label class="text-sm text-gray-600">曲风</label>
                        <select v-model="filters.genre"
                            class="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-gray-500 focus:border-gray-500">
                            <option value="all">全部</option>
                            <option value="classical">古典</option>
                            <option value="jazz">爵士</option>
                            <option value="pop">流行</option>
                        </select>
                    </div>
                    <div class="space-y-1.5">
                        <label class="text-sm text-gray-600">时长</label>
                        <select v-model="filters.duration"
                            class="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-gray-500 focus:border-gray-500">
                            <option value="all">全部</option>
                            <option value="short">短曲 (&lt;3分钟)</option>
                            <option value="medium">中等 (3-5分钟)</option>
                            <option value="long">长曲 (&gt;5分钟)</option>
                        </select>
                    </div>

                    <!-- Third Row -->
                    <div class="space-y-1.5">
                        <label class="text-sm text-gray-600">作曲家</label>
                        <select v-model="filters.composer"
                            class="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-gray-500 focus:border-gray-500">
                            <option value="all">全部</option>
                            <option value="mozart">莫扎特</option>
                            <option value="beethoven">贝多芬</option>
                            <option value="chopin">肖邦</option>
                        </select>
                    </div>
                    <div class="space-y-1.5">
                        <label class="text-sm text-gray-600">年代</label>
                        <select v-model="filters.era"
                            class="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-gray-500 focus:border-gray-500">
                            <option value="all">全部</option>
                            <option value="baroque">巴洛克</option>
                            <option value="classical">古典</option>
                            <option value="romantic">浪漫</option>
                        </select>
                    </div>
                </div>

                <!-- Page Range -->
                <div class="space-y-2">
                    <div class="flex items-center justify-between">
                        <label class="text-sm text-gray-600">页数</label>
                        <div class="flex justify-between text-sm mt-1 text-blue-400">
                            <span>1页面</span>
                            <span>~</span>
                            <span>{{ filters.pages == 11 ? '没有限制' : filters.pages + '页面' }}</span>
                        </div>
                    </div>

                    <div class="px-2">
                        <input type="range" v-model="filters.pages" min="1" max="11"
                            class="w-full h-2 bg-blue-100 rounded-lg appearance-none cursor-pointer" 
                            @input="updatePageDisplay" />
                    </div>
                </div>

                <!-- Price Range -->
                <div class="space-y-2">
                    <label class="text-sm text-gray-600">价格</label>
                    <div class="flex items-center gap-2">
                        <div class="relative flex-1">
                            <span class="absolute left-3 top-2.5 text-gray-500">$</span>
                            <input  v-model="filters.minPrice"
                                class="w-full pl-7 pr-3 py-2 border rounded-lg focus:ring-2 focus:ring-gray-500 focus:border-gray-500"
                                placeholder="0" />
                            <span class="absolute right-3 top-2.5 text-gray-400">USD</span>
                        </div>
                        <span class="text-gray-400">~</span>
                        <div class="relative flex-1">
                            <span class="absolute left-3 top-2.5 text-gray-500">$</span>
                            <input  v-model="filters.maxPrice"
                                class="w-full pl-7 pr-3 py-2 border rounded-lg focus:ring-2 focus:ring-gray-500 focus:border-gray-500"
                                placeholder="0" />
                            <span class="absolute right-3 top-2.5 text-gray-400">USD</span>
                        </div>
                    </div>
                </div>

                <!-- Footer Buttons -->
                <div class="flex items-center justify-between pt-4">
                    <button type="button" @click="resetFilters"
                        class="flex items-center text-gray-500 hover:text-gray-700">
                        <svg class="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                        </svg>
                        初始化
                    </button>
                    <div class="flex gap-3">
                        <button type="button" @click="closeModal"
                            class="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg bg-gray-100">
                            取消
                        </button>
                        <button type="submit" class="px-4 py-2 bg-gray-900 text-white rounded-lg hover:bg-gray-800">
                            查看2,228份曲谱
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";

const filters = ref({
    instrument: "all",
    difficulty: "all",
    genre: "all",
    duration: "all",
    composer: "all",
    era: "all",
    pages: 11,
    minPrice: "",
    maxPrice: "",
});

const closeModal = () => {
    // Emit close event to parent
    emit("close");
};

const resetFilters = () => {
    filters.value = {
        instrument: "all",
        difficulty: "all",
        genre: "all",
        duration: "all",
        composer: "all",
        era: "all",
        pages: 50,
        minPrice: "",
        maxPrice: "",
    };
};

const applyFilters = () => {
    // Emit filters to parent
    emit("apply", filters.value);
};

// Define emits
const emit = defineEmits(["close", "apply"]);
</script>
<style scoped>
select {
    outline: none;
    /* 去掉outline */
    transition: background-color 0.3s;
    /* 添加过渡效果 */
    background-color: transparent;
    /* 使背景透明，避免影响选项 */
}

option {
    background-color: rgba(211, 211, 211, 0.2);
    border: 0;


}
</style>