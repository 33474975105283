<template>
    <!-- Header -->
    <header class="fixed top-0 left-0 right-0 z-50 bg-white border-b border-gray-100">
        <div class="mx-auto px-4 flex items-center justify-between h-16" style="max-width: 1140px">
            <div class="flex items-center gap-8">
                <router-link to="/main">
                  <!--
                    <img src="https://imgproxy.mapia.io/static/mms/logo-min.svg" alt="MyMusicSheet" width="159" height="35"
                        class="h-[35px] w-[159px]" />
                        -->
                  <span class="font-medium">乐谱汇</span>
                </router-link>
                <nav class="hidden md:flex items-center gap-6 font-bold">
                    <router-link to="/"
                        class="text-red-500 hover:bg-gray-100 hover:rounded-lg text-sm px-2 py-1 cursor-pointer">曲谱</router-link>
                  <!--
                    <router-link to="/"
                        class="text-gray-600 hover:text-gray-900 hover:bg-gray-100 hover:rounded-lg text-sm px-2 py-1 cursor-pointer">课程</router-link>
                    <router-link to="#"
                        class="text-gray-600 hover:text-gray-900 hover:bg-gray-100 hover:rounded-lg text-sm px-2 py-1 cursor-pointer">音源</router-link>
                  -->
                </nav>
            </div>
            <div class="flex items-center gap-2">
                <div class="relative">
                  <Search></Search>
                </div>

              <div class="hover:bg-gray-100 duration-100 px-2 py-2 rounded" @click="router.push({path:'/payment'})">
                <LiaRandomSolid size="20" color="#777" />
              </div>
              <div class="duration-100 rounded inline hover:bg-gray-100 px-2 py-2 overflow-hidden" @click="router.push({path:'/payment'})">

                <AiOutlineShopping class="inline" size="20" color="#777"></AiOutlineShopping>
                <div v-if="false" class="absolute inline size-1.5 rounded-full bg-red-400 ring-2 ring-white text-white text-sm" ></div>
                <div v-if="cartItemsNum !== 0" class="absolute inline size-1.5 rounded-full  text-red-500 text-[12px]" >{{cartItemsNum}}</div>

            </div>
              <!--
                <button class="bg-white border border-500 text-gray-600 px-2 py-1 rounded-lg hover:bg-gray-100 text-sm">
                    销售乐谱
                </button>
                <button @click="showLoginModal = true"
                    class="bg-blue-500 text-white px-2 py-1 rounded-lg hover:bg-blue-600 text-sm flex items-center gap-1">
                    <LiaUserSolid size="16" />注册 · 登入
                </button> -->
            </div>
        </div>
    </header>

    <!-- Login Modal -->
    <div v-if="showLoginModal" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <!-- 登录 -->
        <div class="bg-white rounded-xl shadow-lg w-full max-w-md p-7" v-if="showLogin">
            <div class="flex justify-between items-center mb-4">
                <div class="flex items-center gap-2">
                    <button @click="showLoginModal = false"
                        class="text-gray-400 bg-gray-100 hover:bg-gray-200 rounded-full p-1 hover:text-gray-600">
                        <svg class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <h2 class="text-lg font-semibold">登入</h2>
                </div>
                <div class="bg-gray-600 text-white px-3 py-2 rounded-lg flex items-center gap-1 text-xs"
                    @click="() => { showRegister = true; showLogin = false }">
                    注册
                    <CgChevronRight size="16" color="#777" />
                </div>


            </div>
            <form @submit.prevent="login" class="space-y-4">
                <div>
                    <div class="mt-2 relative rounded-md shadow-sm">
                        <input type="email" name="email"
                            class="block w-full pl-10 pr-3 py-3 border border-gray-100 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            placeholder="输入电子邮箱地址">
                        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <AiOutlineMail color="#999" size="16" />
                        </div>
                    </div>
                </div>
                <div>
                    <div class="mt-1 relative rounded-md shadow-sm">
                        <input type="password" name="password"
                            class="block w-full pl-10 pr-3 py-3 border border-gray-100 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            placeholder="输入密码">
                        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <MdVpnKey color="#999" size="16" />
                        </div>
                    </div>
                </div>
                <div class="flex items-center justify-between">
                    <button type="submit"
                        class="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 text-sm">登入</button>
                </div>
                <div class="flex items-center justify-center">
                    <a href="#" class="text-sm text-gray-500 hover:text-gray-700 text-sm">找回密码</a>
                </div>
            </form>
            <div class="mt-6 flex items-center justify-center text-sm">
                <div class="flex items-center w-full">
                    <hr class="flex-grow border-gray-100">
                    <span class="text-gray-500 mx-2">or</span>
                    <hr class="flex-grow border-gray-100">
                </div>
            </div>
            <div class="mt-6 flex justify-center space-x-4">
                <button class="bg-white border border-gray-300 rounded-lg p-2 hover:bg-gray-50 h-16 w-16">
                    <img src="https://www.mymusicsheet.com/assets/img/login/google-login.png" alt="google"
                        class="h-full w-full">
                </button>
                <button class="bg-white border border-gray-300 rounded-lg hover:bg-gray-50 h-16 w-16">
                    <svg _ngcontent-ms-c1661571849="" xmlns="http://www.w3.org/2000/svg" class="h-full w-full"
                        viewBox="6 6 44 44" style="overflow: visible;">
                        <g _ngcontent-ms-c1661571849="" fill="none" fill-rule="evenodd">
                            <path _ngcontent-ms-c1661571849="" fill="#000" fill-rule="nonzero"
                                d="M28.2226562,20.3846154 C29.0546875,20.3846154 30.0976562,19.8048315 30.71875,19.0317864 C31.28125,18.3312142 31.6914062,17.352829 31.6914062,16.3744437 C31.6914062,16.2415766 31.6796875,16.1087095 31.65625,16 C30.7304687,16.0362365 29.6171875,16.640178 28.9492187,17.4494596 C28.421875,18.06548 27.9414062,19.0317864 27.9414062,20.0222505 C27.9414062,20.1671964 27.9648438,20.3121424 27.9765625,20.3604577 C28.0351562,20.3725366 28.1289062,20.3846154 28.2226562,20.3846154 Z M25.2929688,35 C26.4296875,35 26.9335938,34.214876 28.3515625,34.214876 C29.7929688,34.214876 30.109375,34.9758423 31.375,34.9758423 C32.6171875,34.9758423 33.4492188,33.792117 34.234375,32.6325493 C35.1132812,31.3038779 35.4765625,29.9993643 35.5,29.9389701 C35.4179688,29.9148125 33.0390625,28.9122695 33.0390625,26.0979021 C33.0390625,23.6579784 34.9140625,22.5588048 35.0195312,22.474253 C33.7773438,20.6382708 31.890625,20.5899555 31.375,20.5899555 C29.9804688,20.5899555 28.84375,21.4596313 28.1289062,21.4596313 C27.3554688,21.4596313 26.3359375,20.6382708 25.1289062,20.6382708 C22.8320312,20.6382708 20.5,22.5950413 20.5,26.2911634 C20.5,28.5861411 21.3671875,31.013986 22.4335938,32.5842339 C23.3476562,33.9129053 24.1445312,35 25.2929688,35 Z">
                            </path>
                        </g>
                    </svg>
                </button>
                <button class="bg-[#3975E9] border border-gray-300 rounded-lg  p-4 h-16 w-16">
                    <img src="https://www.mymusicsheet.com/assets/img/login/fb-login.png" alt="fb"
                        class="h-full w-full">
                </button>
                <button class="bg-[#2DC000] border border-gray-300 rounded-lg  p-4 h-16 w-16">
                    <img src="https://www.mymusicsheet.com/assets/img/login/wechat-login.svg" alt="wechat"
                        class="h-full w-full">
                </button>

            </div>
        </div>
        <!-- 注册 -->
        <div class="bg-white rounded-xl shadow-lg w-full max-w-md p-7" v-if="showRegister">
            <div v-if="showRegisterHint">
                <div class="flex justify-between items-center mb-4">
                    <div class="flex items-center gap-2">
                        <button @click="showLoginModal = false"
                            class="text-gray-400 bg-gray-100 hover:bg-gray-200 rounded-full p-1 hover:text-gray-600">
                            <svg class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <h2 class="text-lg font-semibold">注册</h2>
                    </div>
                    <div class="bg-gray-600 text-white px-3 py-2 rounded-lg flex items-center gap-1 text-xs"
                        @click="() => { showRegister = false; showLogin = true }">
                        登入
                        <CgChevronRight size="16" color="#777" />
                    </div>
                </div>

                <div class="rounded p-4 shadow">
                    <p class=" font-semibold mb-6 mt-4">
                        MyMusicSheet中，汇集了来自世界各地艺术家的各种乐谱和亚洲最大的KPOP, JPOP内容。
                    </p>
                    <ol class="flex justify-start gap-20 text-sm">
                        <li class="flex flex-col gap-2 border-l-[2px] border-[#3975E9] pl-2">
                            <span>艺术家人数</span>
                            <span class="font-bold">9K+</span>
                        </li>
                        <li class="flex flex-col gap-2 border-l-[2px] border-[#3975E9] pl-2">
                            <span>用户数</span>
                            <span>800K+</span>
                        </li>
                        <li class="flex flex-col gap-2 border-l-[2px] border-[#3975E9] pl-2">
                            <span>乐谱数</span>
                            <span>300K+</span>
                        </li>
                    </ol>
                </div>
                <div class="flex justify-between mt-4 mb-4">
                    <p>请同意我们的条款和条件。</p>
                    <div class="flex items-center gap-2 cursor-pointer ">查看条款
                        <RiShareBoxLine color="#999" size="16" />
                    </div>
                </div>
                <div class="flex gap-4 items-center text-sm mb-4">
                    <input type="checkbox" /> 同意
                </div>
                <p class="pl-10 text-sm flex gap-1 items-center">
                    <AiFillSafetyCertificate size="16" color="#777" /> 请选择加入方式
                </p>
                <div class="mt-6 flex justify-center space-x-4">
                    <button class="bg-white border border-gray-300 rounded-lg p-2 hover:bg-gray-50 h-16 w-16">
                        <img src="https://www.mymusicsheet.com/assets/img/login/google-login.png" alt="google"
                            class="h-full w-full">
                    </button>
                    <button class="bg-white border border-gray-300 rounded-lg hover:bg-gray-50 h-16 w-16">
                        <svg _ngcontent-ms-c1661571849="" xmlns="http://www.w3.org/2000/svg" class="h-full w-full"
                            viewBox="6 6 44 44" style="overflow: visible;">
                            <g _ngcontent-ms-c1661571849="" fill="none" fill-rule="evenodd">
                                <path _ngcontent-ms-c1661571849="" fill="#000" fill-rule="nonzero"
                                    d="M28.2226562,20.3846154 C29.0546875,20.3846154 30.0976562,19.8048315 30.71875,19.0317864 C31.28125,18.3312142 31.6914062,17.352829 31.6914062,16.3744437 C31.6914062,16.2415766 31.6796875,16.1087095 31.65625,16 C30.7304687,16.0362365 29.6171875,16.640178 28.9492187,17.4494596 C28.421875,18.06548 27.9414062,19.0317864 27.9414062,20.0222505 C27.9414062,20.1671964 27.9648438,20.3121424 27.9765625,20.3604577 C28.0351562,20.3725366 28.1289062,20.3846154 28.2226562,20.3846154 Z M25.2929688,35 C26.4296875,35 26.9335938,34.214876 28.3515625,34.214876 C29.7929688,34.214876 30.109375,34.9758423 31.375,34.9758423 C32.6171875,34.9758423 33.4492188,33.792117 34.234375,32.6325493 C35.1132812,31.3038779 35.4765625,29.9993643 35.5,29.9389701 C35.4179688,29.9148125 33.0390625,28.9122695 33.0390625,26.0979021 C33.0390625,23.6579784 34.9140625,22.5588048 35.0195312,22.474253 C33.7773438,20.6382708 31.890625,20.5899555 31.375,20.5899555 C29.9804688,20.5899555 28.84375,21.4596313 28.1289062,21.4596313 C27.3554688,21.4596313 26.3359375,20.6382708 25.1289062,20.6382708 C22.8320312,20.6382708 20.5,22.5950413 20.5,26.2911634 C20.5,28.5861411 21.3671875,31.013986 22.4335938,32.5842339 C23.3476562,33.9129053 24.1445312,35 25.2929688,35 Z">
                                </path>
                            </g>
                        </svg>
                    </button>
                    <button class="bg-[#3975E9] border border-gray-300 rounded-lg  p-4 h-16 w-16">
                        <img src="https://www.mymusicsheet.com/assets/img/login/fb-login.png" alt="fb"
                            class="h-full w-full">
                    </button>
                    <button class="bg-[#2DC000] border border-gray-300 rounded-lg  p-4 h-16 w-16">
                        <img src="https://www.mymusicsheet.com/assets/img/login/wechat-login.svg" alt="wechat"
                            class="h-full w-full">
                    </button>
                    <button
                        class="bg-[#fff] border border-gray-300 rounded-lg  p-4 h-16 w-16 flex flex-col gap-2 items-center"
                        @click="() => { showRegisterHint = false; showRegisterForm = true; }">
                        <AiOutlineMail size="24" color="#999" class="h-full w-full" />
                        <span>Email</span>
                    </button>
                </div>
            </div>

            <div v-if="showRegisterForm">
                <div class="flex justify-between items-center mb-4">
                    <div class="flex items-center gap-2">
                        <button @click="() => { showRegisterForm = false; showRegisterHint = true }"
                            class="text-gray-400 bg-gray-100 hover:bg-gray-200 rounded-full p-1 hover:text-gray-600">
                            <CgChevronLeft size="16" color="#777"></CgChevronLeft>
                        </button>
                        <h2 class="text-lg font-semibold">登记电子邮箱</h2>
                    </div>
                </div>
                <!-- email注册表单 -->
                <form @submit.prevent="login" class="space-y-4">
                    <div>
                        <div class="mt-2 relative rounded-md shadow-sm">
                            <input type="email" name="email"
                                class="block w-full pl-10 pr-3 py-3 border border-gray-100 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                placeholder="电子邮箱">
                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <AiOutlineMail color="#999" size="16" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="mt-1 relative rounded-md shadow-sm">
                            <input type="password" name="password"
                                class="block w-full pl-10 pr-3 py-3 border border-gray-100 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                placeholder="密码">
                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <MdVpnKey color="#999" size="16" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="mt-1 relative rounded-md shadow-sm">
                            <input type="password" name="password"
                                class="block w-full pl-10 pr-3 py-3 border border-gray-100 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                placeholder="请确认及再次输入密码">
                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <MdVpnKey color="#999" size="16" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="mt-1 relative rounded-md shadow-sm">
                            <input type="text" name="username"
                                class="block w-full pl-10 pr-3 py-3 border border-gray-100 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                placeholder="用户名">
                        </div>
                    </div>
                    <div class="flex items-center justify-between">
                        <button type="submit"
                            class="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 text-sm">注册</button>
                    </div>
                </form>
            </div>


        </div>

    </div>
</template>

<script setup>
import {computed, ref} from 'vue';
import { LiaUserSolid, LiaRandomSolid } from "vue-icons-plus/lia";
import { AiOutlineShopping, AiOutlineMail, AiFillSafetyCertificate } from "vue-icons-plus/ai"
import { CgChevronLeft, CgChevronRight } from "vue-icons-plus/cg";
import { MdVpnKey } from "vue-icons-plus/md"
import { RiShareBoxLine } from "vue-icons-plus/ri"
import Search from "@/components/layout/Search.vue";
import {useRouter} from "vue-router";
const router = useRouter()
const showLoginModal = ref(false);
const showLogin = ref(true);
const showRegister = ref(false);
const showRegisterHint = ref(true);
const showRegisterForm = ref(false);

const cartItemsNum = computed(()=>{
  if (localStorage.getItem('cart')){
    const ids = JSON.parse(localStorage.getItem("cart"));
    return ids.length
  }

  return 0
})

</script>