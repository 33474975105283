<template>
  <div class="min-h-screen bg-white">
    <Header></Header>
    <!-- Navigation -->
    <nav class="border-b mt-16 border-gray-100">
      <div class="mx-auto px-4" style="max-width: 1140px">
        <div class="flex items-center gap-6 h-12">
          <div class="py-3 px-4 text-[15px] text-[#BFC3C7] hover:text-gray-900 cursor-pointer"
               :class="{ 'active': selectedParams.instrumentIDs.length === 0 }" @click.prevent="clearSelectedParams();modifyQueryAndFetch('instrumentIDs', [])">全部</div>

          <div v-for="(item, index) in navItems" :key="index" class="relative group" v-on:mouseenter="subItemShow=index" v-on:mouseleave="subItemShow=-1">

            <div @click.prevent="modifyQueryAndFetch('instrumentIDs', item.ids)" :class="{'active': isInstrumentsActive(item)}"
              class="py-3 px-4 text-[15px] cursor-pointer text-[#BFC3C7] hover:text-gray-900 hover:bg-gray-100 flex gap-1 items-center">
              {{ item.name }}
              <BiChevronDown size="16" v-if="item.subItems" />
            </div>

            <div v-if="item.subItems&&subItemShow === index"
              :class="['absolute left-0 w-24 bg-white border border-gray-200 rounded-lg transition-opacity duration-300', index === subItemShow? 'opacity-100':'opacity-0']">
              <ul>
                <li v-for="(subItem, subIndex) in item.subItems" :key="subIndex"
                  class="px-4 py-2 hover:bg-gray-100 cursor-pointer text-sm" @click="modifyQueryAndFetch('instrumentIDs', [subItem.id]);subItemShow=-1">
                  <div >{{ subItem.name }}</div>
                </li>
              </ul>
            </div>


          </div>
        </div>
      </div>
    </nav>

    <!-- Main Content -->
    <main class="mx-auto px-4 py-6" style="max-width: 1140px">
      <div class="flex gap-6">
        <!-- Sidebar -->
        <div class="w-[220px]  border-r border-gray-100 ">
          <div class="mb-4 flex gap-2 bg-gray-100 justify-between px-1 py-1 rounded-lg mr-3">
            <button class="px-4 py-2 w-1/2 bg-white rounded-lg text-xs">近期</button>
            <button class="px-4 py-2 w-1/2 text-xs text-gray-400">热门</button>
          </div>
          <div class="space-y-4">
            <div v-for="(section, index) in sidebarSections.filter(data => data.items.length>0)" :key="index">
              <h3 class=" mb-2" style="color:#9EA4AA">{{ section.title }}</h3>
              <ul class="ml-3 space-y-2 text-sm">
                <li v-for="(item, idx) in section.items" class="py-0" :key="idx">
                  <div @click="section.name.endsWith('IDs')?modifyQueryAndFetch(section.name, [item.id], true): modifyQueryAndFetch(section.name, item.id, true)"
                    :class="['p-0 cursor-pointer hover:bg-gray-30 h-4',!item.selected&&item.count?'text-gray-700':'',item.count? '':'text-gray-400' , item.selected? 'text-red-500':'hover:text-gray-800']">{{ item.name
                    }}<span class="text-gray-400 text-xs p-0">({{
                        item.count ? item.count : 0
                    }})
                    <AiOutlineClose class="inline text-red-500" size="12" v-if="item.selected"></AiOutlineClose>
                  </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Content -->
        <div class="flex-1">
          <!-- Match List -->
          <div class="mb-6">
            <div class="flex justify-between items-center">
              <div class="text-gray-600 text-sm mb-4">为您匹配的最佳结果</div>
              <div class="flex gap-2">
                <div class="left cursor-pointer" @click="prev()" :disabled="currentPage === 1">
                  <CgChevronLeft size="16" color="#777" />
                </div>
                <div class="right cursor-pointer" @click="next()" :disabled="currentPage === pages">
                  <CgChevronRight size="16" color="#777" />
                </div>
              </div>
            </div>


            <div>
              <ul class="grid grid-cols-5 gap-4">
                <li v-for="(item, index) in matchList" :key="index"
                  class="border rounded px-3 py-2 cursor-pointer hover:bg-gray-100 flex gap-2">
                  <img :src="item.img" alt="" class="w-[30px] h-[30px] rounded">
                  <div>
                    <div class="text-gray-600 text-xs">{{ item.type }}</div>
                    <div class="text-gray-900 text-xs truncate" style="max-width: 100px;">{{ item.name }}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <!-- Music Sheet List -->
          <div class="space-y-4">
            <div v-for="(sheet, index) in musicSheets" :key="index" @click.prevent="goSheetDetail(sheet.id)"
              class="flex items-center py-4 px-2 border-b border-gray-100 text-xs gap-6 hover:bg-gray-50 cursor-pointer">
              <div class="w-[295px]">
                <h3 class="font-medium">{{ sheet.title }}</h3>
                <p class="text-sm text-gray-600">{{ sheet.composer }}</p>
              </div>
              <div class="flex items-center gap-2 w-[150px]">
                <img :src="sheet.avatar" alt="" class="w-[30px] h-[30px] rounded-full">
                <div class="text-gray-600">{{ sheet.artist.name }}</div>
              </div>
              <div class="text-gray-600 w-[80px]">{{ sheet.instruments.map(x => x.name).toString() }}</div>
              <div class="text-gray-600  text-center w-20">
               {{parseSheetDifficulty(sheet.difficulty)}}
              </div>
              <div class="font-medium w-[80px]">￥{{ parseSheetPrice(sheet.price) }}</div>
              <button class=" px-1 py-1 border rounded hover:bg-gray-50 text-red-400" @click="addToCart(sheet.id)">
                <AiOutlineShopping size="18"/>
              </button>
              <!--
              <button class="px-1 py-1">
                <BiFile size="14" color="#999" />
              </button> -->
            </div>
          </div>

          <!-- Pagination -->
          <div class="mt-6 flex justify-center gap-2 text-xs">
            <button class="px-1 py-1 border rounded hover:bg-gray-50" :disabled="currentPage === 1" @click="prev()">
              <CgChevronLeft size="16" color="#777" />
            </button>
            <button v-for="(page, idx) in showPages" :key="idx" class="px-2 py-1 rounded"
              :class="{ 'bg-red-100 text-red-500': page === currentPage }" @click="setCurrentPage(page)">{{ page
              }}</button>
            <button class="px-1 py-1 border rounded hover:bg-gray-50" :disabled="currentPage === pages"
              @click="next()">
              <CgChevronRight size="16" color="#777" />
            </button>


          </div>
        </div>
      </div>
    </main>

    <Footer></Footer>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import Header from '../../components/layout/Header.vue'
import Footer from '../../components/layout/Footer.vue'
import {  AiOutlineClose, AiOutlineShopping } from "vue-icons-plus/ai"
import { CgChevronLeft, CgChevronRight } from "vue-icons-plus/cg";
import {  BiChevronDown } from "vue-icons-plus/bi"
import {getSheetList, getSheetCount, getSheetCountList} from '@/api/sheet'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus';
import {parseSheetDifficulty, parseSheetPrice} from "../../parser/sheet";

const router = useRouter()
const subItemShow = ref(-1)
const navItems = [
  { name: '钢琴 / 键盘', ids: ['100', '108'], subItems: [{name:'钢琴', id:'100'}, {name:'手风琴', id:'108'}]},
  { name: '吉他 / 拨弦', ids: ['70', '74', '82', '78', '86'],
    subItems: [
      { name: '木吉他', id: '70' },
      { name: '电吉他', id: '74' },
      { name: '尤克里里', id: '82' },
      { name: '贝斯', id: '78' },
      { name: '竖琴', id: '86' },
    ]},
  { name: '弓弦', ids: ['50', '54', '58', '62'],
    subItems: [
      { name: '小提琴', id: '50' },
      { name: '中提琴', id: '54' },
      { name: '大提琴', id: '58' },
      {name: '低音提琴', id: '62' },
    ] },

  { name: '木管', ids:['2', '6', '10', '14', '18'] ,
    subItems: [
      { name: '长笛', id: '2' },
      { name: '萨克斯', id: '18' },
      { name: '单簧管', id: '6' },
      { name: '双簧管', id: '10' },
      { name: '巴松', id: '14' },
    ]},
  { name: '铜管', ids: ['30', '34', '38', '42'],
    subItems:[
      { name: '小号', id: '30' },
      { name: '圆号', id: '34' },
      { name: '长号', id: '38' },
      { name: '大号', id: '42' },
    ] },
  { name: '民乐' , ids: ['140','144', '188', '184', '180', '196'],
    subItems: [
        { name: '竹笛', id: '140' },
      { name: '陶笛', id: '144' },
      { name: '古筝', id: '188' },
      { name: '二胡', id: '184' },
      { name: '琵琶', id: '180' },
      { name: '扬琴', id: '196' },
    ]},
  { name: '打击乐', ids: ['120','124'] ,
    subItems: [
      {name: '架子鼓', id: '120' },
      {name: '马林巴琴', id: '124' },
    ]},

]

const sidebarSections = ref([
  {
    name: 'difficulty',
    title: '难易度',
    items: [
      { name: '初学者', id: '1', count: 0, selected:false},
      { name: '容易', id: '2', count: 0, selected:false },
      { name: '普通', id: '3', count: 0, selected:false },
      { name: '高难度', id: '4', count: 0, selected:false },
      { name: '专业级', id: '5', count: 0, selected:false }
    ]
  },
  {
    name:'instrumentIDs',
    title:'乐器',
    items: []
  },
  {
    name: 'tagIDs',
    title: '类型',
    items:[
      { name: '流行', id: '1', count: 0, selected:false },

      { name: '古典', id: '3', count: 0, selected:false },
      { name: '爵士', id: '4', count: 0, selected:false },

      { name: '嘻哈', id: '6', count: 0, selected:false },
      { name: '摇滚', id: '2', count: 0, selected:false },

      { name: '凯尔特', id: '25', count: 0, selected:false },
      { name: 'R&B', id: '7', count: 0, selected:false },
      { name: 'K-Pop', id: '8', count: 0, selected:false },
      { name: '民谣', id: '13', count: 0, selected:false },
      { name: '串烧', id: '14', count: 0, selected:false },
      { name: '动画', id: '17', count: 0, selected:false },
      { name: '乐队', id: '18', count: 0, selected:false },
      { name: 'Fusion', id: '20', count: 0, selected:false },
      { name: '儿歌', id: '23', count: 0, selected:false },

      { name: '朋克', id: '26', count: 0, selected:false },
      { name: '蓝调', id: '28', count: 0, selected:false },
      { name: '独创', id: '27', count: 0, selected:false },
      //{ name: '查看更多', id: '95' }
    ]
  },
  {
    name: 'orchestra',
    title: '配器法',
    items: [
      { name: 'Solo', id: '1', count: 0 },
      { name: '二重奏', id: '2', count: 0 },
      { name: '三重奏', id: '3', count: 0 },
      { name: '四重奏', id: '4', count: 0 },
      { name: '五重奏', id: '5', count: 0 },
      { name: '多人合奏', id: '6', count: 0 },
      { name: '管弦乐团', id: '7', count: 0 },
      { name: '乐队', id: '8', count: 0 },
      { name: '伴奏', id: '9', count: 0 }
    ]
  },
  {
    name: 'hasChords',
    title: '和弦',
    items: [
      { name: '未包括', id: 'false', count: 0 },
      { name: '包括', id: 'true', count: 0 }
    ]
  },
  {
    name:'hasLyrics',
    title: '歌词',
    items: [
      { name: '不包括', id: 'false', count: 0 },
      { name: '包括', id: 'true', count: 0 }
    ]
  }
])

const matchList = [
  { name: 'Yorushika', type: '音乐家', img: 'https://imgproxy.mapia.io/v3/ZupMd9_3J9M85pjfvDsbht0onDUkpEfZ/g:ce/rs:fill:400:400:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NDFlMmY5NzU4MTMz/YWE0NDgzOWZmZTE1/NDU4M2Q3ZjcvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1r/b2tvbXU.jpg' },
  { name: '机智的医生生活', type: '音乐家', img: 'https://imgproxy.mapia.io/v3/ZupMd9_3J9M85pjfvDsbht0onDUkpEfZ/g:ce/rs:fill:400:400:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NDFlMmY5NzU4MTMz/YWE0NDgzOWZmZTE1/NDU4M2Q3ZjcvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1r/b2tvbXU.jpg' },
  { name: '进击的巨人', type: '音乐家', img: 'https://imgproxy.mapia.io/v3/ZupMd9_3J9M85pjfvDsbht0onDUkpEfZ/g:ce/rs:fill:400:400:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NDFlMmY5NzU4MTMz/YWE0NDgzOWZmZTE1/NDU4M2Q3ZjcvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1r/b2tvbXU.jpg' },
  { name: 'IVE', type: '音乐家', img: 'https://imgproxy.mapia.io/v3/ZupMd9_3J9M85pjfvDsbht0onDUkpEfZ/g:ce/rs:fill:400:400:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NDFlMmY5NzU4MTMz/YWE0NDgzOWZmZTE1/NDU4M2Q3ZjcvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1r/b2tvbXU.jpg' },
  { name: 'BLACKPINK', type: '音乐家', img: 'https://imgproxy.mapia.io/v3/ZupMd9_3J9M85pjfvDsbht0onDUkpEfZ/g:ce/rs:fill:400:400:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NDFlMmY5NzU4MTMz/YWE0NDgzOWZmZTE1/NDU4M2Q3ZjcvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1r/b2tvbXU.jpg' },
  { name: 'Official髭男dism', type: '音乐家', img: 'https://imgproxy.mapia.io/v3/ZupMd9_3J9M85pjfvDsbht0onDUkpEfZ/g:ce/rs:fill:400:400:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NDFlMmY5NzU4MTMz/YWE0NDgzOWZmZTE1/NDU4M2Q3ZjcvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1r/b2tvbXU.jpg' },
  { name: 'Kessoku Band', type: '音乐家', img: 'https://imgproxy.mapia.io/v3/ZupMd9_3J9M85pjfvDsbht0onDUkpEfZ/g:ce/rs:fill:400:400:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NDFlMmY5NzU4MTMz/YWE0NDgzOWZmZTE1/NDU4M2Q3ZjcvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1r/b2tvbXU.jpg' },
  { name: '千与千寻', type: '音乐家', img: 'https://imgproxy.mapia.io/v3/ZupMd9_3J9M85pjfvDsbht0onDUkpEfZ/g:ce/rs:fill:400:400:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NDFlMmY5NzU4MTMz/YWE0NDgzOWZmZTE1/NDU4M2Q3ZjcvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1r/b2tvbXU.jpg' },
  { name: 'Ado', type: '音乐家', img: 'https://imgproxy.mapia.io/v3/ZupMd9_3J9M85pjfvDsbht0onDUkpEfZ/g:ce/rs:fill:400:400:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NDFlMmY5NzU4MTMz/YWE0NDgzOWZmZTE1/NDU4M2Q3ZjcvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1r/b2tvbXU.jpg' },
  { name: 'Beatles', type: '音乐家', img: 'https://imgproxy.mapia.io/v3/ZupMd9_3J9M85pjfvDsbht0onDUkpEfZ/g:ce/rs:fill:400:400:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NDFlMmY5NzU4MTMz/YWE0NDgzOWZmZTE1/NDU4M2Q3ZjcvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1r/b2tvbXU.jpg' }
]
const musicSheets = ref([])
const currentPage = ref(1)
const pageSize = ref(10)
const listCount = ref(0)
const pages = computed(
    ()=>{
      return Math.ceil(listCount.value / pageSize.value)
    }
)
const showPages = computed(()=>{
  const curr = currentPage.value
  const diff = curr%5
  const x = curr - diff
  const li = []



  if (pages.value >= x + 5 || diff === 0) {
    return diff !== 0 ? [x + 1, x + 2, x + 3, x + 4, x + 5]:[x - 4, x - 3, x - 2, x - 1, x]
  }

  const maxDiff =  pages.value%5
  for (let i = 1; i < maxDiff + 1; i++) {
    li.push(pages.value - maxDiff + i)
  }

  return li

})
const modifyQueryAndFetch = async (k, v, bySide=false) => {
  currentPage.value = 1
  const params = selectedParams.value
  switch (k) {
    case 'instrumentIDs':
      if (!bySide){
        params[k] = v.filter(id => id.trim() !== "");
      }else{
        // 由侧边栏点击
        params[k] = params[k].filter(id => id !== v[0])
      }
      break;
    case 'tagIDs':  params[k] = v.filter(id => id.trim() !== ""); break;
    default: params[k] = v?.trim(); break;
  }



  selectedParams.value = params
  updateSection(k, v, bySide)
  await fetchSheetList()
  await fetchSheetCountList()
}

// 修改
function updateSection(name, selectedID, bySide){
  const sections = sidebarSections.value
  const params = selectedParams.value
    for (let idx in sections){
      // 未找到对应 key
      if (sections[idx].name !== name) {
        continue
      }

      if (name === "instrumentIDs") {
        sections[idx].items = Array.from(params.instrumentIDs, v =>  {
          let name = ""
          for (let item of navItems){
            for (let subitem of item.subItems){
              if (subitem.id === v){
                name = subitem.name
                break
              }
            }
          }

          return {
            id: v,
            count:0,
            selected:true,
            name: name
          }
        })
      }

      sections[idx].items = Array.from(sections[idx].items, v => {

        // 点击了已选中的
        if (v.selected){
          if (name !== "instrumentIDs"){
            v.selected = false
            params[name] = ""
          }else if (bySide) {
            v.selected = selectedID.indexOf(v.id) === -1
          }
          return v
        }

        if (name === "tagIDs") {
          v.selected = selectedID.indexOf(v.id) !== -1 // 包含
        }
        else
         {
          v.selected = v.id === selectedID;
        }
        return v
      })
      break
    }

}

function getCurrentQueryParams() {
  const queryParams = {}
  const params = selectedParams.value
  const instrs = params.instrumentIDs
  const key = params.searchKey
  const tags = params.tagIDs
  const diff = params.difficulty
  const o = params.orchestra

  const lyrics = params.hasLyrics
  const chords = params.hasChords

  if (key && key !== ""){queryParams.searchKey = key}
  if (instrs && instrs.length > 0) {queryParams.instrument = instrs }
  if (tags && tags.length > 0) {queryParams.tag = tags }
  if (diff&&diff !== '0'){queryParams.difficulty = diff }
  if (o && o !== '0'){queryParams.orchestra = o}
  if (lyrics){ queryParams.hasLyrics = lyrics }
  if (chords){ queryParams.hasChords = chords }

  return queryParams
}

const fetchSheetList = async () => {
  // 创建
  const params = getCurrentQueryParams()
  params.limit =  pageSize.value
  params.offset = (currentPage.value - 1) * pageSize.value

  listCount.value = (await getSheetCount(params)).data;
  const sheets = (await getSheetList(params)).data;
  console.log(sheets)
  sheets.forEach(element => {
    element.avatar = `${window.API.DEV_SERVER}/file/user/${element.artistID}/avatar`
  });


  musicSheets.value = sheets;
  router.push({ query: params });

}

const fetchSheetCountList = async () => {
  const params = getCurrentQueryParams()
  const counts = (await getSheetCountList(params)).data;
  if (!counts.orchestra){return}
  const sections = sidebarSections.value
  console.log(counts)
  for (let k of ['orchestra','difficulty','tagIDs', 'hasLyrics', 'hasChords', 'instrumentIDs']) {
    for (let idx in sections){
      // 未找到对应 key
      if (sections[idx].name !== k) {
        continue
      }
      sections[idx].items = Array.from(sections[idx].items, v => { v.count = counts[k][v.id]; return v })
      break
    }
  }
  sidebarSections.value = sections;
}

const setCurrentPage = (page) => {
  currentPage.value = page
  fetchSheetList()
}

const prev = () => {
  let i = 0
  const curr = currentPage.value
  while (i < curr){
    if (i + 5 < curr){
      i += 5
      }else{
      break
    }
  }

  if (i !== 0) {
    setCurrentPage(i)
  }
}

const next = () => {
  const curr = currentPage.value
  const max = pages.value
  const diff = curr%5
  let i = curr - diff + 1

  if (diff === 0 && i < max){
    setCurrentPage(i)
  }

  if (i + 5 <= max){
    setCurrentPage(i + 5)
  }
}



const goSheetDetail = (id) => {
  router.push({ path: 'detail/' + id })
}

const isInstrumentsActive = (item) => {
  const ids = item.ids
  const selects = selectedParams.value.instrumentIDs

  if (ids.indexOf(String(selects)) !== -1){
    return true
  }
  if (selects.length === 0 || !selects.every){
    return false
  }
  return selects.every(a => ids.some(b => a === b));

}

const addToCart = (sheetID) => {
  sheetID = parseInt(sheetID)
  const cart = JSON.parse(localStorage.getItem('cart')) || [];
  if (!cart.includes(sheetID)) {
    cart.push(sheetID);
    localStorage.setItem('cart', JSON.stringify(cart));
    ElMessage.success('添加购物车成功');
  } else {
    ElMessage.warning('该商品已在购物车中');
  }
}


const selectedParams = ref({
  instrumentIDs: [],
  tagIDs :  [],
  hasLyrics : null,
  hasChords : null,
  difficulty :  null,
  orchestra : null,
  searchKey : null,
})

function updateSectionAll(){
  const params = selectedParams.value
  for (let k in params){
    updateSection(k, params[k])
  }
}

function setSelectedParamsFromQuery() {
  const params = {
    instrumentIDs:  router.currentRoute.value.query.instrument || [],
    tagIDs : router.currentRoute.value.query.tag || [],
    hasLyrics : router.currentRoute.value.query.hasLyrics || null,
    hasChords :router.currentRoute.value.query.hasChords || null,
    difficulty : router.currentRoute.value.query.difficulty || null,
    orchestra : router.currentRoute.value.query.orchestra || null,
    searchKey : router.currentRoute.value.query.searchKey || null
  }

  const instrIDs = params.instrumentIDs
  params.instrumentIDs = instrIDs.every?instrIDs: [instrIDs]

  const tagIDs = params.tagIDs
  params.tagIDs = tagIDs.every? tagIDs: [tagIDs]

  // 设置到
  selectedParams.value = params
}
function clearSelectedParams() {
  selectedParams.value = {
    instrumentIDs: [],
    tagIDs :  [],
    hasLyrics : null,
    hasChords : null,
    difficulty :  null,
    orchestra : null,
    searchKey : null,
  }
  router.push({query: {}})
  const sections = sidebarSections.value
  for (let idx in sections) {
    sections[idx].items = Array.from(sections[idx].items, v => { v.selected = false; return v })
  }

}

onMounted(() => {
  setSelectedParamsFromQuery()
  updateSectionAll();
  fetchSheetList();
  fetchSheetCountList();
});

</script>
<style scoped>
.active {
  border-bottom: 3px solid black;
  color: #000;
}
</style>